@import '~styles/variables';
@import '~styles/mixins';

.ant-layout {
  margin-top: 64px;

  & .ant-spin.ant-spin-spinning.ant-spin-show-text {
    @include position(absolute, $left: 50%, $z-index: 1);
    @include transform(translate(-50%, -50%));
    display: inherit;
    margin: 100px auto;
    pointer-events: none;
  }
}

.ant-next-ei {
  &.ant-modal-cta-authored {
    & .ant-modal {
      &-content {
        border-radius: 30px
      }

      &-body {
        padding: 0;
      }
    }
  }
}

.ant-next-calendar {
  &.ant-modal-cta-authored {
    @media (max-width: 767px) {
      margin: 0;
      overflow-x: hidden;
    }
    & .ant-modal {
      &-body {
        padding: 0;
      }
      &-content {
        border-radius: 0;
        background-color: $th-light-purple;
      }
      &-close {
        &-x {
          font-size: 24px;
          font-weight: bold;
          color: black;
          margin-top: 6px;
          margin-right: 6px;
        }
      }
    }
  }
}

.ant-next {
  &.ant-modal-cta-authored {
    @media (max-width: 767px) {
      max-width: calc(100vw - 40px);
    }
    & .ant-modal {
      &-close {
        @include transform(translate(50%, -50%));
        @include border-style(none, $radius: 50%);
        overflow: hidden;

        &-x {
          width: 36px;
          height: 36px;
          line-height: 36px;
          font-size: 20px;
          background-color: #171C34;
          color: #ffffff;
        }
      }

      &-content {
        border-radius: 5px;
      }

      &-body {
        padding: 0;
      }
    }
  }

  &.ant-form {
    .ant-submit-btn {
      color: #ffffff;
      background-color: $th-cyan;
      border: 1px solid $th-cyan;
      height: 50px;
      font-size: 16px;
      cursor: pointer;
      border-radius: 5px;
      width: 100%;

      &:disabled {
        color: rgba(0, 0, 0, 0.25);
        background-color: #f5f5f5;
        border-color: #d9d9d9;
        text-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        cursor: not-allowed;
      }
    }

    .ant-error-btn {
      color: #ffffff;
      background-color: #d6292f;
      border: 1px solid #d6292f;
      height: 50px;
      font-size: 16px;
      cursor: pointer;
      border-radius: 5px;
      width: 100%;

      &:disabled {
        color: #ffffff;
        background-color: darken(#d6292f, 25%);
        border-color: darken(#d6292f, 25%);
        text-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        cursor: not-allowed;
      }
    }

    label {
      font-size: 16px;
      font-weight: 300;
    }

    textarea.ant-input {
      font-size: 16px;
      padding: 10px 21px;
    }

    .ant-input {
      &textarea {

      }
    }

    .ant-checkbox-wrapper {
      .ant-checkbox-inner {
        width: 18px;
        height: 18px;

        &:after {
          left: 31%;
        }
      }

      &.ant-checkbox-green {

        &:hover .ant-checkbox-inner,
        &:hover .ant-checkbox-inner,
        &:focus + .ant-checkbox-inner {
          border-color: $th-cyan;
        }

        .ant-checkbox {
          &-checked {

            &:after {
              border-color: $th-cyan;
            }

            .ant-checkbox-inner {
              border-color: $th-cyan;
              background-color: $th-cyan;
            }
          }
        }
      }
    }


  }

  &.ant-form-large,
  &.ant-form-medium {
    .atn-input {
      border-radius: 3px;
      color: #D7D9DB;
      border: 1px solid #D7D9DB;
    }
  }

  &.ant-form-large {
    .ant-input-lg {
      height: 50px;
    }
    .ant-form-item {
      margin-bottom: 11px;
    }
  }

  &.ant-form-medium {
    .ant-form-item {
      margin-bottom: 8px;
    }
  }

  &.ant-switch {
    min-width: 48px;
    height: 24px;

    .ant-switch-loading-icon, &::after {
      width: 14px;
      height: 14px;
    }

    &:after {
      top: 4px;
      left: 4px;
    }

    &-checked {
      background-color: $th-cyan;
      &::after {
        margin-left: -4px;
        left: 100%;
      }
    }
  }

  &.ant-slider {
    &.ant-slider-with-marks {
      padding: 10px;
      height: 24px;

      .ant-slider-dot-active,
      .ant-slider-dot {
        display: none;
      }

      .ant-slider-handle {
        height: 24px;
        width: 24px;
        margin-top: -10px;
        border: 6px solid #E8DAF1;
        background-color: $th-purple;

        &:focus {
          border-color: #E8DAF1;
          outline: none;
          -webkit-box-shadow: 0 0 0 5px rgba(125, 63, 168, 0.2);
          box-shadow: 0 0 0 5px rgba(125, 63, 168, 0.2);
        }
      }

      .ant-slider-rail,
      .ant-slider-track,
      .ant-slider-step {
        height: 5px;
      }

      .ant-slider-mark {
        top: 27px;
      }


      .ant-slider-track {
        background-color: $th-purple;
      }

      .ant-slider-rail {
        background-color: #EDEDED;
      }

      .ant-slider-mark-text {
        color: $th-black;
        opacity: .4;
        font-size: 18px;
        line-height: 22px;
        @include maxWidth1000{
          color: #fff;
        }
      }

      .ant-slider-mark-text-active {
        color: $th-black;
        opacity: 1;
        @include maxWidth1000{
          color: #fff;
        }
      }
    }
  }

  &.ant-select {
    &.ant-filters-select {
      font-size: 18px;
      line-height: 22px;
      color: $th-black;
      display: block;
      border: none;
      position: relative;
      margin-top: 6px;
      padding-right: 15px;

      &:after {
        content: ' ';
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translateY(-50%) rotate(90deg);
        width: 6px;
        height: 9px;
        background: {
          image: url('static/img/chevron.svg');
          size: contain;
          position: center;
          repeat: no-repeat;
        };
      }

      .ant-select-selection {
        border: none;
        height: auto;

        &-selected-value {
          white-space: normal;
        }
      }

      .ant-select-selection__rendered {
        margin: 0;
        line-height: 22px;
        overflow: hidden;
      }

      &.ant-select-focused .ant-select-selection,
      &.ant-select-open .ant-select-selection,
      .ant-select-selection:focus,
      .ant-select-selection:active {
        border: none;
        box-shadow: none;
      }

      .ant-select-selection__placeholder, .ant-select-search__field__placeholder {
        font-size: inherit;
        line-height: inherit;
        color: $th-black;
      }
    }
  }
}
.mobileFilter{
  &.ant-modal {
    width: 100% !important;
    margin: 0 !important;
    max-width:none !important;
    padding: 0 !important;
    bottom: 0;
    top:unset;
    position: fixed;
    .ant-modal-content {
      background-color: #E9EFF7;
      box-shadow:none;
    }
    .ant-modal-body{
      padding:142px 38px 28px 38px ;
    }
  }

}
.ant-priceSlider{
  &.ant-slider {
    &.ant-slider-with-marks {
      padding: 10px;
      height: 24px;

      .ant-slider-dot-active,
      .ant-slider-dot {
        display: none;
      }

      .ant-slider-handle {
        height: 10px;
        width: 10px;
        margin-top: -4px;
        background-color: #1391FF;
        border:none;

        &:focus {
          outline: none;
          // -webkit-box-shadow: 0 0 0 5px rgba(125, 63, 168, 0.2);
          // box-shadow: 0 0 0 5px rgba(125, 63, 168, 0.2);
        }
      }

      .ant-slider-rail,
      .ant-slider-track,
      .ant-slider-step {
        height: 2px;
      }

      .ant-slider-mark {
        top: 27px;
      }


      .ant-slider-track {
        background-color: #1391FF;
      }

      .ant-slider-rail {
        background-color: #000;
        opacity: 0.15;
        @include maxWidth1000{
          background-color: #fff;
          opacity: 0.8;
        }
      }

      .ant-slider-mark-text {
        color: $th-black-A1;
        font-size: 12px;
        line-height: 24px;
        @include maxWidth1000{
          color: #fff;
        }
      }

    }
  }

}
.ant-priceSlider{
  &.searchTutors{
     &.ant-slider {
    &.ant-slider-with-marks {
      .ant-slider-handle {
        background-color: #414141;
      }

      .ant-slider-track {
        background-color: #414141;
      }
    }
  }

  }
}

.ant-calendar-input  {
  min-height: 25px;
}

.ant__purple-checkbox {
  @include display-flex($align: center);

  .ant-checkbox {
    margin: 0 auto;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $th-purple;
    border-color: $th-purple;
    color: $th-purple;
  }
  .ant-checkbox + span {
    padding-right: 0;
    padding-left: 0;
    width: 100%;
  }
}

.ant__price-slider.ant-slider {
  .ant-slider-track {
    height: 8px;
    transform: translate(0px, -100%);
    background-color: $th-purple;
  }

  .ant-slider-handle {
    width: 18px;
    height: 18px;
    transform: translate(0, -17%);
    z-index: 3;
    border: 6px solid $th-purple;

    &:hover {
      border: 6px solid $th-purple;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
  &:hover .ant-slider-handle,
  .ant-slider-handle {
    transform: translate(-60%,-40%)!important;
    border-color: $th-purple;
  }

  .ant-slider-step {
    height: 8px;
    border-radius: 5px;
    transform: translate(0, -100%);
  }
  .ant-slider-dot {
    @include transform(translate(0, 70%));
    @include square(5px);
    background-color: $th-grey1;
    margin-left: -3px;
  }
  .ant-slider-rail {
    height: 8px;
    transform: translate(0, -110%);
  }

  .ant-slider-dot-active,
  .ant-slider-dot:last-child,
  .ant-slider-dot:first-child {
    display: none;
  }
}

.dashboard-table {
  @include border-style($radius: 10px 10px 0px 0px);

  .ant-table-body {
    overflow-y: unset!important;
  }

  table {
    thead tr th {
      @include font-style(16px, normal, $th-cyan, $lineHeight: 21px);
      background: $th-light-green;
    }

    tbody tr td {
      @include font-style(14px, normal, $th-black, $lineHeight: 19px);
      padding: 16px 4px;
    }
  }

  .ant-table-placeholder {
    border-bottom: none;

    .ant-empty-description {
      @include font-style(14px, normal, rgba(0, 0, 0, 0.25), center);
      max-width: 140px;
      margin: 0 auto;
    }
  }
}

.leader-table {
  @include border-style($radius: 10px 10px 0px 0px);

  table {
    thead tr th {
      @include font-style(16px, normal, $th-cyan, $lineHeight: 21px);
      background: $th-light-green;
    }

    tbody tr td {
      @include font-style(14px, normal, $th-black, $lineHeight: 19px);
      padding: 16px 4px;
    }
  }

  .ant-table-placeholder {
    border-bottom: none;

    .ant-empty-description {
      @include font-style(14px, normal, rgba(0, 0, 0, 0.25), center);
      max-width: 140px;
      margin: 0 auto;
    }
  }
}

.ant-search_result_pagination {
  @include display-flex(center);

  .ant-pagination-next,
  .ant-pagination-prev {
    display: none;
  }

  .ant-pagination-item {
    @include display-flex(center, center);
    @include border-style(none);
    background: transparent;
    cursor: pointer;

    > * {
      @include font-style(16px, normal, $th-middle-gray, center, 21px);
    }

    &.ant-pagination-item-active {
      background: $th-purple-middle;

      > * {
        @include font-style(16px, normal, white, center, 21px);
      }
    }
  }
}

.ant__green-checkbox {

  .ant-checkbox-checked .ant-checkbox-inner {
    @include border-style(1px solid $th-cyan);
    background-color: $th-cyan;
  }
}

.antd-bookings-tabs {
  .ant-tabs-bar.ant-tabs-top-bar {
    @include border-style(none);

    .ant-tabs-nav {
      .ant-tabs-ink-bar {
        background-color: $th-purple;
      }

      .ant-tabs-tab {
        @include font-style(16px, 600, $th-grey1, center, 21px);
        padding: 0px 10px;
        margin-right: 40px;

        &.ant-tabs-tab-active {
          @include font-style(16px, 600, $th-purple, center, 21px);
        }
      }
    }
  }
}
.spinLoading{
  .ant-spin-dot{
    font-size: 40px;
    .ant-spin-dot-item{
      width: 16px;
      height:16px;
    }
  }
}
@media screen and (max-width: 767px) {
  .ant-modal-centered::before {
    display: none;
  }
}
.ant-modal-centered::before {
  display: inline;
}

.serailTimeStudentPopover{
  .ant-popover-inner-content{
    background-color: #27d6c0;
    overflow-y: auto;
    max-height: 200px;
    border-radius: 6px;
  }
}

@media screen and (max-width: 767px) {
  input, select, textarea {
    font-size: 16px!important;
  }

  .leader-table {
    table {
      thead tr th {
        padding: 16px 9px;
      }
    }
  }

  .modal-select {
    @include position(fixed, 0!important, 0, 0, 0!important, 1001);
    background-color: rgba(0, 0, 0, 0.45);
    padding: 24px;
    width: 100%!important;

    &:not(.ant-select-dropdown-hidden) {
       @include display-flex();
    }

    & > div {
      background: white;
      max-height: 80vh;
      margin: auto;

      & > ul {
        min-width: 300px;
        max-height: 60vh;
      }
    }
  }
}

