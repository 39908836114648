/**
* Variables
*/
$th-grey: #686f75;
$th-grey1: #8f8f8f;
$th-grey2: #d1d1d1;
$th-grey3: #9a9c9e;
$th-grey4: #4f4950;
$th-grey5: #979797;
$th-light-grey: #ededed;
$th-dark-grey: #686369;
$th-dark-grey2: #77787c;
$th-secondary: #77869e;
$th-purple: #7d3fa8;
$th-purple-middle: #c9afdb;
$th-light-purple: #a98dbb;
$th-cyan: #27d6c0;
$th-dark-cyan: #23ac9e;
$th-light-cyan: #008848;
$th-dark-violet: #32325d;
$th-pink: #ff7291;
$th-yellow: #ffd565;
$th-black: #130217;
$th-light-black: #4e4e4e;
$th-light-purple: #f8f4fb;
$th-middle-gray: #62686e;
$th-light-grey: #E8EAED;
$th-inligth-grey: #BDC1C6;
$th-purple-medium: #e5dfe9;
$th-purple-medium2: #af96c1;
$th-light-green: #f2fdfb;
$th-medium-green: #7be2d8;
$th-dark-green: #009F7D;
$th-green: #00D4A6;
$th-alert: #ffa704;
$th-error: #df2065;
$th-white: #ffffff;
$th-han-purple: #4200ff;
$th-red: #ff0000;
$th-light-red:#DF504A;
$th-blue: #2400FF;
$th-light-blue: #0600ffbd;
$th-dark-gray: #595959;
$th-sky-blue: #0e72ed;
$th-dark-black: #131415;
$th-sky-blue2: #0F93FE;
$th-black-A1:#1A1A1A;
